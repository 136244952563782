import React from "react"
import { graphql } from "gatsby"
import { Card } from 'react-bootstrap'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Reco from "../../components/reco"
import FooterBtn from '../../components/ui/footerbtn'
import { fillTemplate } from '../../services/format'
import { getInfluencerDetails } from '../../services/influencerdetails'
import { GenreT } from "../../services/seotemplate"

const Books = ({ data }) => {
    const { edges:recos } = data.allReco
    const { categoryMapping, genre, thumbnail } = recos[0].node.bookObjects[0]

    const templateVars = {}
  
    templateVars.num_of_books = data.allReco.totalCount
    
    if(categoryMapping)
        templateVars.genre = categoryMapping.prefix + " " + categoryMapping.displayName
    else
        templateVars.genre = "in " + genre    
    
    const title = fillTemplate(GenreT.title, templateVars)
    const description = fillTemplate(GenreT.description, templateVars)
    const heading = fillTemplate(GenreT.heading, templateVars)

    return(
        <Layout>
            <SEO title={title} description={description} image={thumbnail}/>
            <h1 className="heading">{heading}</h1>
            <ul style={{ padding: 0 }}>
                {recos.map(reco => {
                    const { id, tweet_id, bookObjects, influencer } = reco.node
                    const { name, hyphen_name } = getInfluencerDetails(influencer)

                    return(
                        <li
                            key={id}
                            style={{
                                textAlign: "center",
                                listStyle: "none",
                                display: "block"
                            }}
                        >
                            <Card id={ tweet_id + '_' + bookObjects[0].title} style = {{marginBottom: "30px"}}> 
                                <Card.Body>
                                    <Reco reco={reco.node} />  
                                </Card.Body>
                                <Card.Footer >
                                    <FooterBtn path={"/books/" + hyphen_name} label={`More by ${name}`}/>
                                </Card.Footer>                         
                            </Card>
                    </li>
                    )
                    
                })}
            </ul>
        </Layout>
    )
}

/*export const query = graphql`
    query($name: String!) 
    {
        allReco(sort: {fields: created_at, order: DESC}, filter: {bookObjects: {elemMatch: {genre: {eq: $name}}}}) {
            totalCount
            edges {
                node {
                    id
                    tweet_id
                    influencer {
                    user_name
                    display_name
                    profile_image
                    }
                    ...BookFragment
                }
            }
        }
    }
    `*/

    export const query = graphql`
    query($name: String!) 
    {
        allReco(sort: {fields: created_at, order: DESC}, filter: {bookObjects: {elemMatch: {categoryMapping: {urlName: {eq: $name}}}}}) {
            totalCount
            edges {
                node {
                    id
                    tweet_id
                    text
                    created_at
                    ...InfluencerFragment
                    ...BookFragment
                }
            }
        }
    }
    `

export default Books
